import { all } from "./permissions";

export const menuItems = [
  {
    id: 5,
    label: "menu.menuitems.users.text",
    icon: "bx-user",
    isAdmin: true,
    auth_users: ["admin", "marketing_team_member"],
    // can_see: super_admin,
    subItems: [
      {
        label: "menu.menuitems.users.subItems.users_management",
        link: "/dashboard/users_management",
        parentId: 4,
        auth_users: ["admin"],

        // can_see: super_admin,
      },
      {
        label: "menu.menuitems.users.subItems.point_of_sale_managers",
        link: "/dashboard/point_of_sale_managers",
        parentId: 4,
        auth_users: ["admin"],

        // can_see: super_admin,
      },
    ],
  },
  {
    id: 1,
    label: "menu.menuitems.hr.text",
    icon: "bx-user-pin",
    isAdmin: true,
    auth_users: ["admin"],

    subItems: [
      {
        label: "menu.menuitems.hr.subItems.designation",
        link: "/dashboard/designation",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.departments",
        link: "/dashboard/departments",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.hr_personal_records",
        link: "/dashboard/hr_personal_records",
        parentId: 1,
        auth_users: ["admin"],
      },
      // {
      //   label: "menu.menuitems.hr.subItems.temp_workers",
      //   link: "/dashboard/temp_workers",
      //   parentId: 1,
      //   can_see: all,
      // },
      // {
      //   label: "menu.menuitems.hr.subItems.attendance",
      //   link: "/dashboard/attendance",
      //   parentId: 1,
      //   can_see: all,
      // },
      {
        label: "menu.menuitems.hr.subItems.holidays",
        link: "/dashboard/holidays",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.leaves",
        link: "/dashboard/leaves",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.performance_management",
        link: "/dashboard/performance_management",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.transfares",
        link: "/dashboard/transfares",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.hr.subItems.complains",
        link: "/dashboard/complains",
        parentId: 1,
        auth_users: ["admin"],
      },
      // {
      //   label: "menu.menuitems.hr.subItems.payroll",
      //   link: "/dashboard/payroll",
      //   parentId: 1,
      //   can_see:all,
      // },
      // {
      //   label: "menu.menuitems.hr.subItems.payroll_reports",
      //   link: "/dashboard/payroll_reports",
      //   parentId: 1,
      //   can_see:all,
      // },
    ],
  },

  {
    id: 4,
    label: "menu.menuitems.pos_stations.subItems.pos_stations",
    icon: "bx-user-pin",
    isAdmin: true,
    auth_users: ["admin"],

    subItems: [
      {
        label: "menu.menuitems.pos_stations.subItems.pos_stations",
        link: "/dashboard/pos_stations",
        parentId: 5,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.subscription_features.subItems.subscription_features",
        link: "/dashboard/subscription-features",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.subscriptions_management.subItems.packages",
        link: "/dashboard/packages",
        parentId: 2,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_subscription_management.subItems.requests",
        link: "/dashboard/requests",
        parentId: 3,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.pos_subscription_management.subItems.pos_subscriptions",
        link: "/dashboard/pos-subscriptions",
        parentId: 4,
        auth_users: ["admin"],
      },
    ],
  },
  {
    id: 4,
    label: "menu.menuitems.pos_hubs.subItems.pos_hubs",
    icon: "bx-user-pin",
    isAdmin: true,
    auth_users: ["admin"],

    subItems: [
      {
        label: "menu.menuitems.pos_hubs.subItems.pos_hubs",
        link: "/dashboard/pos_hubs",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.subscription_features.subItems.subscription_features",
        link: "/dashboard/pos-hub-features",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.subscriptions_management.subItems.packages",
        link: "/dashboard/pos-hub-packages",
        parentId: 2,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_subscription_management.subItems.requests",
        link: "/dashboard/pos-hub-requests",
        parentId: 3,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.pos_subscription_management.subItems.pos_subscriptions",
        link: "/dashboard/pos-hub-subscriptions",
        parentId: 4,
        auth_users: ["admin"],
      },
    ],
  },

  {
    id: 6,
    label: "menu.menuitems.technical_support.text",
    icon: "bx-support",
    isAdmin: true,
    auth_users: ["admin"],

    subItems: [
      {
        label: "menu.menuitems.technical_support.subItems.technical_support",
        link: "/dashboard/technical_support",
        parentId: 1,
        auth_users: ["admin"],
      },
    ],
  },
  {
    id: 7,
    label: "menu.menuitems.pos_accounting.text",
    icon: "bx-money",
    isAdmin: true,
    auth_users: ["admin"],

    subItems: [
      {
        label: "menu.menuitems.pos_accounting.subItems.chart_of_accounts",
        link: "/dashboard/default_chart_of_accounts",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.pos_accounting.subItems.cash_and_banks_subledger",
        link: "/dashboard/default_cash_and_banks_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.assets_subledger",
        link: "/dashboard/default_assets_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.emps_subledger",
        link: "/dashboard/default_emps_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.suppliers_subledger",
        link: "/dashboard/default_suppliers_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.customers_subledger",
        link: "/dashboard/default_customers_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.purchases_subledger",
        link: "/dashboard/default_purchases_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.inventory_subledger",
        link: "/dashboard/default_inventory_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.sales_subledger",
        link: "/dashboard/default_sales_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.pos_accounting.subItems.equity_subledger",
        link: "/dashboard/default_equity_subledger",
        parentId: 1,
        auth_users: ["admin"],
      },
    ],
  },
  {
    id: 20,
    label: "menu.menuitems.archiving.text",
    icon: "bx-data",
    isAdmin: true,
    auth_users: ["admin"],
    subItems: [
      {
        label:
          "menu.menuitems.archiving.subItems.archiving_categories_management",
        link: "/dashboard/archiving_categories_management",
        parentId: 20,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.archiving.subItems.archiving_shelves",
        link: "/dashboard/archiving_shelves",
        parentId: 20,
        auth_users: ["admin"],
      },
      {
        label:
          "menu.menuitems.archiving.subItems.archiving_documents_management",
        link: "/dashboard/archiving_documents_management",
        parentId: 20,
        auth_users: ["admin"],
      },
    ],
  },

  {
    id: 24,
    label: "menu.menuitems.dashboard.text",
    icon: "bx-home-circle",
    isAdmin: true,
    auth_users: ["marketing_team_member",],
    subItems: [
      {
        label: "menu.menuitems.dashboard.subItems.team_member_home",
        link: "/dashboard/team_member_home",
        parentId: 24,
        auth_users: ["marketing_team_member"],
      },
    ],
  },
  {
    id: 28,
    label: "menu.menuitems.surveys.text",
    icon: "bx bxs-report",
    isAdmin: true,
    auth_users: ["marketing_team_member"],
    subItems: [
      {
        label: "menu.menuitems.surveys.subItems.team_member_surveys",
        link: "/dashboard/team_member_surveys",
        parentId: 28,
        auth_users: ["marketing_team_member"],
      },
    ],
  },
  {
    id: 30,
    label: "menu.menuitems.campaigns.text",
    icon: "bx bxs-report",
    isAdmin: true,
    auth_users: ["marketing_team_member"],
    subItems: [
      {
        label: "menu.menuitems.campaigns.subItems.team_member_campaigns",
        link: "/dashboard/team_member_campaigns",
        parentId: 28,
        auth_users: ["marketing_team_member"],
      },
    ],
  },
  {
    id: 21,
    label: "menu.menuitems.marketing.text",
    icon: "dripicons-document",
    isAdmin: true,
    auth_users: ["marketing_manager"],
    subItems: [
      {
        label:
          "menu.menuitems.marketing.subItems.marketing_channels_management",
        link: "/dashboard/marketing_channels_management",
        parentId: 21,
        auth_users: ["marketing_manager"],
      },
      {
        label:
          "menu.menuitems.marketing.subItems.marketing_campaign_management",
        link: "/dashboard/marketing_campaign_management",
        parentId: 21,
        auth_users: ["marketing_manager"],
      },
      {
        label: "menu.menuitems.marketing.subItems.marketing_survey",
        link: "/dashboard/marketing_survey",
        parentId: 21,
        auth_users: ["marketing_manager"],
      },
      {
        label: "menu.menuitems.marketing.subItems.marketing_area_management",
        link: "/dashboard/marketing_area_management",
        parentId: 21,
        auth_users: ["marketing_manager"],
        can_see: all,
      },
      {
        label: "menu.menuitems.marketing.subItems.marketing_team_management",
        link: "/dashboard/marketing_team_management",
        parentId: 21,
        auth_users: ["marketing_manager"],
      },

      // {
      //   label: "menu.menuitems.marketing.subItems.website_cms",
      //   link: "/dashboard/website_cms",
      //   parentId: 21,
      //   can_see: all,
      // },
    ],
  },

  {
    id: 21,
    label: "menu.menuitems.purchases.text",
    icon: "dripicons-document",
    isAdmin: true,
    can_see: all,
    subItems: [
      {
        label: "menu.menuitems.purchases.subItems.purchases_agents_management",
        link: "/dashboard/payments_agents",
        parentId: 21,
        auth_users: ["admin"],
      },
      {
        label: "menu.menuitems.purchases.subItems.purchases",
        link: "/dashboard/payments",
        parentId: 21,
        auth_users: ["admin"],
      },

      {
        label: "menu.menuitems.marketing.subItems.website_cms",
        link: "/dashboard/website_cms",
        parentId: 21,
        can_see: all,
      },
    ],
  },
];
