<script>


/**
 * Nav-bar Component
 */
export default {
  name: "nav-bar",
  data() {
    return {
      lang: "",
      text: null,
      flag: null,
      user_roles: [],
      user: {},
      value: null,
      sideNavHide: false,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "عربي",
        },
      ],
    };
  },
  created() {
    const loggeduser = localStorage.user;
    const decoded_user_data = JSON.parse(loggeduser);
    this.user = decoded_user_data
    this.user_roles = decoded_user_data?.roles.map(
      (item) => item.role
    );
    this.user_roles = [this.user_roles[0]]

    if (!localStorage.lang) return (this.lang = "ar");
    this.lang = "en";
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
      // var main_content = document.querySelector(".main-content")
      // var side_menu = document.getElementById("sidebar-menu")
      if (this.sideNavHide == false && this.$i18n.locale == "ar") {
        // main_content.style.marginRight = "70px"
        this.sideNavHide = true;
        console.log(this.sideNavHide);
        // side_menu.style.position = "relative !important"
        // side_menu.style.left = "38px"
      } else if (this.sideNavHide == true && this.$i18n.locale == "ar") {
        console.log(this.$i18n.locale);
        // main_content.style.marginRight = "250px"
        this.sideNavHide = false;
      } else if (this.sideNavHide == false && this.$i18n.locale == "en") {
        // main_content.style.marginLeft = "70px"
        this.sideNavHide = true;
        console.log(this.sideNavHide);
      } else if (this.sideNavHide == true && this.$i18n.locale == "en") {
        console.log(this.$i18n.locale);
        // main_content.style.marginLeft = "250px"
        this.sideNavHide = false;
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    logout() {
      const data = {
        title: 'messages.alert.logout',
        msg: 'messages.alert.qs_logout'
      }
      this.popup.confirm(data).then((resp) => {
        if (resp) {

          localStorage.removeItem("user");
          this.$router.replace("/login");

        }


      })
    }

  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header" style="padding: 0">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">

        </div>

        <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item" @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
      </div>

      <div class="d-flex" style="
          margin-inline-end: 1rem;
          justify-content: space-between;
          align-items: center;
        ">
        <div class="user-details">
          <span class="user-name ">{{ user.name }} </span>
          <span class="user-roles">
            <template
              v-for="(item, index) in this.$i18n.locale == 'en' ? user_roles.map((item) => item.name_en) : user_roles.map((item) => item.name)"
              :key="index">
              {{ item }}
            </template>
          </span>
        </div>
        <select v-model="$i18n.locale" style="color: #2a3042" class="lang-dropdown border-0 m-0 ms-2">
          <option @click="changeLanguage(locale)" v-for="(locale, i) in languages" :key="`locale-${i}`"
            :value="locale.language">
            {{
              locale.title ? locale.title : lang == "en" ? "English" : "Arabic"
            }}
          </option>
        </select>
        <div class="d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon" @click="logout">
            <i :class="$i18n.locale == 'en' ? 'bx-log-in' : 'bx-log-out'" class="bx text-danger fa-2xl"></i>
          </button>
        </div>

      </div>
    </div>
  </header>
</template>
<style >
.user-details .user-name {
  font-size: 13px;
  font-weight: 600;
  color: #1b2950;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-details .user-roles {
  display: block;
  color: #67748e;
  font-size: 12px;
}
</style>

<script setup>
import { watch } from "vue";
import { useI18n } from "vue-i18n";





const i18n = useI18n();
watch(
  () => i18n.locale.value,
  (newVal) => {
    localStorage.lang = newVal;
    if (localStorage.lang == "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
      // document.body.classList.add("ar")
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
      // document.body.classList.remove("ar")
    }
  }
);




</script>
